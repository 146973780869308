import Router from 'next/router';
export const NEXT_PUBLIC_GA_ID = process.env.NEXT_PUBLIC_GA_ID;
const isDev = !NEXT_PUBLIC_GA_ID || process.env.NODE_ENV === 'development';

import * as analytics from '@/lib/analytics/client';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  console.log(`📈 page view ${url}`);
  if (isDev) {
    return;
  }
  // @ts-ignore
  gtag('config', NEXT_PUBLIC_GA_ID, {
    page_path: url,
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  console.log('📈', action, category, label, `isDev:${isDev}`);
  if (isDev) {
    return;
  }
  // @ts-ignore
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const trackAuthOpenModal = () => {
  analytics.track('auth.modal-opened');
  event({
    action: `auth_open`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthCloseModal = () => {
  analytics.track('auth.modal-closed');
  event({
    action: `auth_close`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthLogin = (type: string) => {
  analytics.track('auth.login', { type });
  event({
    action: `auth_login_${type}`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthLoginError = (type: string) => {
  analytics.track('auth.login-failed', { type });
  event({
    action: `auth_login_error_${type}`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAskDemo = () => {
  event({
    action: `demo_request`,
    category: 'homepage',
    label: window?.location?.pathname,
  });
};

export const trackSeeDetails = (type: 'searched_profile' | 'available_profile', properties: Record<string, any> = {}) => {
  analytics.track(`${type}.viewed-details`, properties);
};

export const track = analytics.track;

Router.events.on('routeChangeComplete', pageview);
