import * as amplitude from '@amplitude/analytics-browser';
import { User } from 'modules/User/interfaces';

const logEvent: typeof console.log = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('📈', ...args);
  }
};

export const init = () => {
  logEvent('Initializing amplitude', process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
  });
};
export const setUserId = amplitude.setUserId;

export const setUser = (user: User) => {
  amplitude.setUserId(user.id);

  const identifyObj = new amplitude.Identify();
  identifyObj.set('name', user.name);
  identifyObj.set('roles', user.roles);
  identifyObj.set('image', user.image);
  identifyObj.set('level', user.level);
  identifyObj.set('countryIds', user.countryIds);
  identifyObj.set('occupationIds', user.occupationIds);
  identifyObj.set(
    'occupationNames',
    (user.occupations || []).map(({ name }: any) => name)
  );
  identifyObj.set('sectorIds', user.sectorIds);
  identifyObj.set(
    'sectorNames',
    (user.sectors || []).map(({ name }: any) => name)
  );

  amplitude.identify(identifyObj, {
    user_id: user.id,
  });
};
export const track: typeof amplitude.track = (...args) => {
  logEvent('track', ...args);
  return amplitude.track(...args);
};
