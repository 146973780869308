import { atcb_action } from 'add-to-calendar-button';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Markdown from 'lib/components/ui/markdown';
import { kebabCase } from 'lodash/fp';
import { event as analyticEvent } from 'modules/Analytics';
import { type Event } from 'modules/App/models/Event';
import { gql, useQuery } from 'modules/GraphQL';
import { getColorAndLabel } from 'modules/Marketing/components/ToolBadge';
import useUser from 'modules/User/hooks/useUser';
import Price from 'modules/common/components/Price';
import Tooltip from 'modules/common/components/Tooltip';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import dynamic from 'next/dynamic';
import React from 'react';
import { MdCalendarViewMonth, MdClose } from 'react-icons/md';
const Calendar = dynamic(() => import('modules/common/components/Calendar'), { ssr: false });
type EventCalendarProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { formatMinutesToHoursAndMinutes } from 'utils/misc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const EVENT_FIELDS = gql`
  fragment EventFields on Event {
    _id
    tool {
      name
      author
      authorUrl
      description
      duration
      type
      pricePro
      priceProId
      priceStandard
      priceStandardId
      priceProUrl
      priceStandardUrl
    }
    duration
    startAt
    endAt

    name
    author
    authorUrl
    description
  }
`;

export const LIST_EVENTS = gql`
  query Events {
    events(sort: "-updatedAt") {
      pageInfo {
        count
        page
        limit
      }
      items {
        ...EventFields
      }
    }
  }
  ${EVENT_FIELDS}
`;

const EventCalendar: React.FC<EventCalendarProps> = ({ className, ...props }) => {
  const { hasPermission } = useUser();
  const { whatsappDorothee } = usePublicRuntimeConfig();
  const { data } = useQuery(LIST_EVENTS, { variables: {} });
  const { items = [] } = data?.events || {};
  const events = items as Event[];

  return (
    <div className={classNames('', className)} {...props}>
      <Calendar
        events={events}
        className="min-h-[80vh]"
        showAllEvents
        components={{
          event: ({ event: originalEvent }) => {
            const event = originalEvent as Event;
            const { _id, startAt: start, endAt: end, tool } = event;

            const name = event.name || tool.name;
            const duration = event.duration || tool.duration || 0;
            const author = event.author || tool.author;
            const authorUrl = event.authorUrl || tool.authorUrl;
            const description = event.description || tool.description;
            const url = event.url || tool.url;
            const priceStandard = tool.priceStandard || 0;
            const pricePro = tool.pricePro || 0;
            const buyProUrl = tool.priceProUrl;
            const buyStandardUrl = tool.priceStandardUrl;

            const isPast = dayjs(start).isBefore(dayjs());
            return (
              <Tooltip
                placement="top"
                showArrow
                trigger={['click']}
                overlay={
                  <div className="calendar-popover">
                    <header>
                      <button
                        onClick={() => {
                          const tooltips = document.querySelectorAll('.rc-tooltip') as unknown as HTMLElement[];
                          tooltips.forEach((tooltip) => {
                            tooltip.style.display = 'none';
                          });
                        }}
                      >
                        <MdClose />
                      </button>
                    </header>
                    <h2>{name}</h2>
                    <a href={authorUrl} className="block text-gray-700 link" target="_blank" rel="noopener noreferrer">
                      {author || tool.author}
                    </a>
                    <strong className="text-faded">{dayjs(start).format('dddd ll')}</strong>
                    <small className="text-faded italic inline-block ml-2">
                      {dayjs(start).format('HH[h]mm')} {end && dayjs(end).format('HH[h]mm')}
                    </small>
                    <div className="prose prose-sm mt-5">
                      <Markdown>{description || tool.description}</Markdown>
                    </div>
                    <footer className="flex gap-2">
                      {!isPast && (
                        <button
                          className="btn btn-xl btn-white w-[280px] sm:w-auto"
                          onClick={() => {
                            analyticEvent({ action: `event-add-to-calendar-${_id}`, category: 'calendar', label: _id });
                            return atcb_action({
                              name,
                              description: description || tool.description || '',
                              startDate: dayjs(start).format('YYYY-MM-DD'),
                              startTime: dayjs(start).format('HH:mm'),
                              endTime: dayjs(end).format('HH:mm'),
                              options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
                              forceOverlay: true,
                              language: 'fr',
                              timeZone: 'Europe/Paris',
                              iCalFileName: kebabCase(name || ''),
                              location: url || window.location.href,
                            });
                          }}
                        >
                          <MdCalendarViewMonth />
                          Ajouter au calendrier
                        </button>
                      )}
                      {!buyStandardUrl ? (
                        <a
                          className="btn btn-primary btn-xs px-3 py-2 h-12"
                          href={url || whatsappDorothee}
                          target="_blank"
                          onClick={() => analyticEvent({ action: `event-participate-${_id}`, category: 'calendar', label: _id })}
                          rel="noopener noreferrer"
                        >
                          Participer
                        </a>
                      ) : (
                        <>
                          {buyStandardUrl && (
                            <a
                              className={classNames('btn btn-secondary btn-xs px-3 py-2 h-12 flex items-center gap-2', {
                                'line-through cursor-not-allowed btn-disabled': hasPermission('pro'),
                              })}
                              onClick={() => analyticEvent({ action: `event-buy-standard-${_id}`, category: 'calendar', label: _id })}
                              href={buyStandardUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {priceStandard === 0 ? 'Gratuit' : <Price value={priceStandard} currency="EUR" className="text-lg font-bold"></Price>}
                              {priceStandard === pricePro ? <span className="badge badge-sm">PROMO</span> : null}
                            </a>
                          )}
                          {buyProUrl &&
                            (hasPermission('pro') ? (
                              <a
                                className="btn btn-primary btn-xs px-3 py-2 h-12"
                                href={buyProUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => analyticEvent({ action: `event-get-pro-free-${_id}`, category: 'calendar', label: _id })}
                              >
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                Pro
                              </a>
                            ) : (
                              <a
                                className="btn btn-primary btn-xs px-3 py-2 h-12 flex items-center gap-2"
                                href={whatsappDorothee}
                                target="_blank"
                                onClick={() => analyticEvent({ action: `event-buy-pro-${_id}`, category: 'calendar', label: _id })}
                                rel="noopener noreferrer"
                              >
                                {pricePro === 0 ? (
                                  <span className="text-lg font-bold">Gratuit</span>
                                ) : (
                                  <Price value={pricePro} currency="EUR" className="text-lg font-bold"></Price>
                                )}
                                <div className="flex flex-col gap-1">
                                  {pricePro < priceStandard && (
                                    <span className="badge badge-sm badge-primary">
                                      <Price value={pricePro - priceStandard} currency="EUR" className=""></Price>
                                    </span>
                                  )}
                                  <span>Passer Pro</span>
                                </div>
                              </a>
                            ))}
                        </>
                      )}
                    </footer>
                  </div>
                }
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const tooltips = document.querySelectorAll('.rc-tooltip') as unknown as HTMLElement[];
                    tooltips.forEach((tooltip) => {
                      tooltip.style.display = 'none';
                    });
                  }}
                >
                  <div className="flex items-center gap-2">{name}</div>
                  <small className="flex items-center justify-between gap-2">
                    <span>{dayjs(start).format('HH:mm')}</span>
                    <span className="font-bold">{formatMinutesToHoursAndMinutes(duration)}</span>
                  </small>
                </div>
              </Tooltip>
            );
          },
        }}
        dayPropGetter={(date) => {
          const today = dayjs().startOf('day');
          return {
            className: dayjs(date).isBefore(today) && !dayjs(date).isSame(today, 'day') ? 'rbc-past-day bg-gray-100' : '',
          };
        }}
        eventPropGetter={({ tool: { type }, startAt: start }: any) => {
          return {
            className: classNames(getColorAndLabel(type).color, {
              'opacity-50': dayjs(start).isBefore(dayjs()) && !dayjs(start).isSame(dayjs(), 'day'),
            }),
          };
        }}
        startAccessor={(event: any) => new Date(event.startAt)}
        endAccessor={(event: any) => new Date(event.endAt)}
      />
    </div>
  );
};

export default EventCalendar;
