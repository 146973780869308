const config = require('./config/i18n');

module.exports = {
  ...config,
  locales: ['en', 'fr'],
  defaultLocale: 'en',
  pages: {
    '*': ['header', 'footer', 'auth', 'common', 'common.seo', 'common.table'],
    'rgx:/blog': ['blog.common'], // using regex to match case-studies index and single page, see: https://github.com/aralroca/next-translate#3-configuration
    '/calendar': ['common.calendar'],
    '/admin/events': ['common.calendar'],
    // '*': ['common', 'header', 'footer', 'follow-us'],
    // '/': ['homepage', 'instances'],
    // 'rgx:/admin': ['common.table'], // using regex to match case-studies index and single page, see: https://github.com/aralroca/next-translate#3-configuration
    // '/stats': ['stats'],
    // '/budget': ['budget'],
    // '/media': ['media'],
    // '/subscribe': ['subscribe'],
  },
};
