export const getType = (value: any) => {
  const match = Object.prototype.toString.call(value).match(/ (\w+)]/);
  return match && match[1].toLocaleLowerCase();
};
export const formatMinutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h`;
  }

  if (remainingMinutes > 0) {
    formattedTime += ` ${remainingMinutes}min`;
  }

  return formattedTime.trim();
};
